<template>
  <div v-if="user" class="profile">

    <div v-if="showModal" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
            <div class="modal-header__left">
                <!--<img @click="modalGoBack" src="@/assets/ic_nav_back.svg" alt="">-->
            </div>
            <div class="modal-header__center">
                {{ modalTitle }}
            </div>
            <div class="modal-header__right">
                <img class="modal-header__close" @click="closeModalStack" src="@/assets/ic_nav_close.svg" alt="">
            </div>
            </div>
            <router-view @setModalTitle="setModalTitle"></router-view>
        </div>
    </div>

    <!-- Profil Header -->
    <div class="profile__header">
      <label for="photoFile">
        <div class="profile__avatar">
          <div v-if="user.photoURL != null" class="profile__avatar__button">
            <img src="@/assets/ic_profile_button.svg" alt="" />
          </div>
          <div class="profile__avatar__mask">
            <img src="@/assets/profile_avatar_mask.svg" alt="" />
          </div>
          <img v-if="photoURL != null" :src="photoURL" alt="" />
        </div>
      </label>
      <div class="profile__name">{{ displayName }}</div>
      <!-- <div v-if="premiumStatus.validTo" class="profile__badge profile__badge--premium">Premium</div>
      <div v-else class="profile__badge profile__badge--free">Free</div> -->
      <input @change="uploadFile" id="photoFile" type="file" accept="image/*" style=" visibility: hidden; height: 0"/>
    </div>

    <!-- Profile Tabs -->
    <div class="tabs tabs--fullwidth">
      <div class="tabs__content">
         <v-btn @click="displayTab('favourites')" :class="activeDisplay=='favourites' ? 'is-active' : ''" text v-ripple="false">Favoriten</v-btn>
         <v-btn @click="displayTab('settings')" :class="activeDisplay=='settings' ? 'is-active' : ''" text v-ripple="false">Einstellungen</v-btn>
      </div>
    </div>

    <!-- Profile Content -->
    <div class="profile__content">

      <div v-show="activeDisplay=='favourites'" class="tabs__item">
        
        <!-- Zuletzt -->
        <!-- <div v-if="recentTrainingActivities.length > 0" class="section">
          <div class="section__title">
            <h4>Zuletzt</h4>
          </div>
          <div class="contentItem" v-for="item in recentTrainingActivities" :key="item.id" @click="openWorkout(item)">
            <div class="contentItem__icon">
              <img src="@/assets/contentitem/ic_contentitem_workout.svg" alt="" />
            </div>
            <div class="contentItem__content">
              <div class="contentItem__title">
                {{ item.title }}
              </div>
              <div class="contentItem__meta">
                {{ typeName(item) }}・{{ coachName(item) }}
              </div>
            </div>
          </div>
        </div> -->

        <!-- Meine Workouts -->
        <div class="section" v-if="userPlaylists.length > 0">
          <div class="section__title">
            <h4>Meine Workouts</h4>
          </div>
          <div class="contentItem" v-for="item in sortedUserWorkouts" :key="item.uid" @click="openFavourite(item)">
            <div class="contentItem__icon">
              <img src="@/assets/contentitem/ic_contentitem_play.svg" alt="" />
            </div>
            <div class="contentItem__content">
              <div class="contentItem__title">
                {{ item.name }}
              </div>
              <div class="contentItem__meta">
                {{ item.items.length }} Übungen
              </div>
            </div>
          </div>
        </div>

        <!-- Workouts -->
        <div class="section" v-if="filteredFavourites('workouts').length > 0">
          <div class="section__title">
            <h4>Workouts</h4>
          </div>
          <div class="contentItem" v-for="item in filteredFavourites('workouts')" :key="item.articleId" @click="openFavourite(item)">
            <div class="contentItem__icon">
              <img src="@/assets/contentitem/ic_contentitem_workout.svg" alt="" />
            </div>
            <div class="contentItem__content">
              <div class="contentItem__title">
                {{ item.title }}
              </div>
              <div class="contentItem__meta">
                {{ item.subtitle }}
              </div>
            </div>
          </div>
        </div>

        <!-- Magazin -->
        <div class="section" v-if="filteredFavourites('blog.BlogPage').length > 0">
          <div class="section__title">
            <h4>Magazin</h4>
          </div>
          <div class="contentItem" v-for="item in filteredFavourites('blog.BlogPage')" :key="item.articleId" @click="openFavourite(item)" >
            <div class="contentItem__icon">
              <img src="@/assets/contentitem/ic_contentitem_magazin.svg" alt="" />
            </div>
            <div class="contentItem__content">
              <div class="contentItem__title">
                {{ item.title }}
              </div>
              <div class="contentItem__meta">
                {{ item.subtitle }}
              </div>
            </div>
          </div>
        </div>

        <!-- Challenges -->
        <!-- <div class="section">
          <div class="section__title">
            <h4>Challenges</h4>
          </div>
          <div class="contentItem">
            <div class="contentItem__icon">
              <img src="@/assets/contentitem/ic_contentitem_magazin.svg" alt="" />
            </div>
            <div class="contentItem__content">
              <div class="contentItem__title">
                {Titel von Challenge}
              </div>
              <div class="contentItem__meta">
                6 Wochen
              </div>
            </div>
          </div>
        </div> -->

      </div>

      <div v-show="activeDisplay=='settings'" class="tabs__item">

        <div class="list" :class="premiumStatus.validTo ? 'list' : 'list no-premium'">

          <!-- Zugangsdaten -->
          <router-link to="/settings/login" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_zugangsdaten.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Zugangsdaten
              </div>
            </div>
          </router-link>

          <!-- Persönliche Angaben -->
          <router-link to="/settings/personal" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_persoenliche_angaben.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Persönliche Angaben
              </div>
            </div>
          </router-link>

          <!-- Sportarten -->
          <router-link to="/settings/sportstypes" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_sports.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Sportarten
              </div>
            </div>
          </router-link>

          <!-- Premium-Mitgliedschaft -->
          <!-- <router-link to="/settings/premium" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_premium.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div v-if="premiumStatus.validTo" class="list__item__label">
                Premium-Mitgliedschaft
              </div>
              <div v-else class="list__item__label">
                Premium-Mitglied werden
              </div>
            </div>
          </router-link> -->

        </div>

        <div class="list">

          <div class="list__title">
            Einstellungen
          </div>

          <!-- Benachrichtungen -->
          <router-link to="/settings/notifications" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_benachrichtigungen.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Benachrichtungen
              </div>
            </div>
          </router-link>

          <!-- SpielerPlus-Verknüpfung -->
          <!-- <router-link to="" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_spielerplus.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                SpielerPlus-Verknüpfung
              </div>
            </div>
          </router-link> -->

          <!-- Privatsphäre -->
          <!-- <router-link to="/settings/personal" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_privatsphaere.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Privatsphäre
              </div>
            </div>
          </router-link> -->

          <!-- Konto löschen -->
          <router-link to="/settings/delete" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_delete.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Konto löschen
              </div>
            </div>
          </router-link>

        </div>

        <div class="list">

          <div class="list__title">
            Rechtliches & Support
          </div>

          <!-- Neuigkeiten -->
          <!-- <router-link to="/neuigkeiten" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_neuigkeiten.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Neuigkeiten
              </div>
            </div>
          </router-link> -->
          
          <!-- Fragen und Antworten -->
          <router-link to="/support/faq" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_fragen_antworten.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Fragen & Antworten
              </div>
            </div>
          </router-link>

          <!-- Fragen und Antworten -->
          <router-link to="/info/53" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_gesundheit.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Gesundheitshinweise
              </div>
            </div>
          </router-link>

          <!-- Problem -->
          <router-link to="/support/problem" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_problem.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Problem melden
              </div>
            </div>
          </router-link>

          <!-- Impressum -->
          <router-link to="/info/47" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_other.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Impressum
              </div>
            </div>
          </router-link>

          <!-- AGB -->
          <router-link to="/info/48" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_other.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                AGB
              </div>
            </div>
          </router-link>

          <!-- Datenschutzhinweise -->
          <router-link to="/info/49" class="list__item drilldown">
            <div class="list__item__icon">
              <img src="@/assets/ic_32_profile_other.svg" alt="" />
            </div>
            <div class="list__item__content">
              <div class="list__item__label">
                Datenschutzhinweise
              </div>
            </div>
          </router-link>

        </div>

        <v-btn class="button button--primary" @click="logout" data-cy="logout">Logout</v-btn>

      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import firebase from 'firebase';
import { lastActiveNonModal } from '@/router';

export default {
    name: 'Profile',
    data() {
        return {
            user: null,
            photoURL: null,
            displayName: null,
            activeDisplay: "favourites",
            showModal: false,
            modalTitle: "",
            userPlaylists: [],
            favourites: [],
        }
    },
    methods: {
        init(){
            this.user = firebase.auth().currentUser;
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    this.user = firebase.auth().currentUser;
                    this.displayName = this.user.displayName;
                    this.photoURL = this.user.photoURL + '?' + Math.random();
                }
                else {
                    this.$router.push("/sign-in");
                }
            });
            this.$store.dispatch('fetchAllFavourites').then((res) => this.favourites = res);
            this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                this.userPlaylists = res;
            });
        },
        typeName(page) {
            if (page.meta) {
                if ((page.meta.type == "exercise.PlaylistPage") || (page.meta.type == "workout.WorkoutPage")) {
                    return "Workout";
                }
            }
            else {
                return "Unbekannt";
            }
        },
        coachName(page) {
            var coach;
            if (page.coach) {
                coach = this.coaches.find(c => c.id == page.coach.id);
            }
            if (coach) {
                return coach.name;
            }
            return "Unbekannt";
        },
        uploadFile(event) {
            if (event.target.files && event.target.files[0]) {
                let avatarFile = event.target.files[0];
                var reader = new FileReader();
                reader.onload = (e) => this.photoURL = e.target.result;
                reader.readAsDataURL(avatarFile);

                let formData = new FormData();
                formData.append('file', avatarFile);
                formData.append('uid', this.user.uid);

                axios.post('https://apphelper.sportlerplus.com/upload',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(() => {
                    this.user.updateProfile({
                        photoURL: "https://apphelper.sportlerplus.com/avatar/" + this.user.uid
                    }).then(() => {
                        // Update successful.
                        console.log("user photoURL changed");
                    }).catch((error) => {
                        // An error happened.
                        console.log(error);
                    });
                })
                .catch(function(){
                    console.log('Error uploading photo.');
                });
            }
        },
        logout() {
            this.$store.dispatch('userSignOut');
        },
        displayTab(name) {
            this.activeDisplay = name;
        },
        setModalTitle(title) {
            this.modalTitle = title;
        },
        closeModalStack() {
            if (lastActiveNonModal) {
                this.$router.push(lastActiveNonModal.path);
            }
            else {
                this.$router.go(-1);
            }
        },
        modalGoBack() {
            this.$router.go(-1);
        },
        filteredFavourites(filterType) {
            let result = [];
            this.favourites.forEach(function(el) {
                if (el.type == filterType) {
                    result.push(el);
                }
                else if (filterType == "workouts" && (el.type == "exercise.Playlist" || el.type == "workout.WorkoutPage"))
                {
                    result.push(el);
                }
            });
            return result;
        },
        openFavourite(favItem) {
            if (favItem.type == "blog.BlogPage") {
                this.$router.push("/articles/" + favItem.articleId);
            }
            else if (favItem.type == "workout.WorkoutPage") {
                this.$router.push("/workout/" + favItem.articleId);
            }
            else if (favItem.type == "exercise.Playlist") {
                this.$router.push("/playlist/start?playlist_id=" + favItem.articleId);
            }
            else if (favItem.meta && favItem.meta.type == "Custom/UserPlaylist") {
                this.$router.push("/playlist/start?user_playlist_id=" + favItem.uid);
            }
        },
        openWorkout(page) {
            if (page.meta.type == "workout.WorkoutPage") {
                this.$router.push("/workout/" + page.id);
            }
            else if (page.meta.type == "exercise.PlaylistPage") {
                this.$router.push("/playlist/start?playlist_id=" + page.id);
            }
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
        /*recentTrainingActivities() {
            return this.$store.getters.getRecentTrainingActivities
        },*/
        coaches() {
            return this.$store.getters.getCoaches;
        },
        sortedUserWorkouts() {
            let userWorkouts = [...this.userPlaylists];
            return userWorkouts.sort((b, a) => (a.creationDate.localeCompare(b.creationDate)))
        }
    },
    watch: {
        $route: {
            /* eslint-disable no-unused-vars */
            immediate: true,
            handler: function(newVal, oldVal) {
                this.showModal = newVal.meta && newVal.meta.showModal;
                this.modalTitle = newVal.meta && newVal.meta.title || "";
            }
        }
    },
}
</script>

<style lang="scss">
  .modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba($colorBlack, .64);

    .modal-header {
      display: flex;
      padding: 12px 0;

      &__center {
        text-align: center;
        flex: 1;
        font-size: 17px;
        font-weight: 700;
      }

      &__left, &__right {
        display: flex;
        min-width: 24px;
      }

      &__close {
        opacity: .48;
        cursor: pointer;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      background: $colorWhite;
      padding: 6px 20px 24px;
      border-radius: 24px 24px 0 0;
    }
  }

  .profile {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 480px;
    margin: 0px auto;

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: -10px 0 32px;
    }

    &__avatar {
      position: relative;
      width: 104px;
      height: 118px;
      margin: 0px auto 19px;
      background: rgba($colorPrimary, .24);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 4px;
        transform: translate(-50%,-50%);
        background: $colorPrimary;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 24px;
        transform: translate(-50%,-50%);
        background: $colorPrimary;
      }

      &__button {
        display: flex;
        position: absolute;
        z-index: 3;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }

      &__mask {
        position: absolute;
        z-index: 2;
        display: flex;
        pointer-events: none;
      }
      
      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__name {
      font-size: 22px;
      font-weight: 700;
      letter-spacing: -0.5px;
      line-height: 28px;
      color: $colorBlack;
      width: 100%;
      text-align: center;
      margin-bottom: 7px;
    }

    &__badge {
      display: inline-flex;
      margin: 0px auto;
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      letter-spacing: -0.13px;
      text-transform: uppercase;
      padding: 4px 5px 3px 6px;
      border-radius: 2px;

      &--premium {
        color: $colorWhite;
        background: $colorPrimary;
      }

      &--free {
        color: rgb($colorBlack, .48);
        background: rgb($colorBlack, .08);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100vw;
      transform: translateX(-50%);
      padding: 24px 0 40px;
      background: $colorBackground;
      margin: -20px 0 -26px 50%;
    }

    .tabs {

      &__item {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);
        max-width: 520px;
        margin: 0px auto;
      }
    }

    .list__item {
      box-shadow: none;

      &:not(:last-child) {
        .list__item__content {
          box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
        }
      }
    }

    .button {
      margin-top: 24px;
    }

    .no-premium {
      display: flex;
      flex-direction: column;

      .list__item {
        order: 2;

        &:nth-child(3) {
          order: 2;

          .list__item__content {
            box-shadow: none;
          }
        }

        &:last-child {
          order: 1;

          .list__item__content {
            box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
          }
        }
      }
    }
  }
</style>